:root {
    --theme-primary-color: #0562e8;
    --theme-secondary-color: #0052ce;
	--theme-light-color: #0562e8;
	--theme-border-color: #ccc;
	--default-font-size:16px;
	--default-font-family:inherit;
	--default-border-width:2px;
	--default-icon-size: 42px;
}
.form-control {
	width: 100%;
	height: 42px;
	border-width: var(--default-border-width);
	border-style:  solid;
	border-color:  var(--theme-border-color);
	border-radius: 3px;
	padding: 6px 16px;
	line-height: 40px;
	height: 42px;
	transition: all .4s ease;
	box-shadow: none;
	outline: none;
	font-size:var(--default-font-size);
}
.form-control.input-compact {
	height: 36px;
	line-height: 34px;
	font-size: calc(var(--default-font-size) - 2px); 
	padding: 0 12px;
}
.form-control.input-large {
	height: 50px;
	line-height: 48px;
	font-size:  calc(var(--default-font-size) + 2px);
}
.form-control:focus,.form-control:active {
	border-color: var(--theme-primary-color);
	background-color:rgba(var(--theme-primary-color), 0.8);
	outline: none;
	box-shadow: none;
}
.input-group{
	position: relative;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	width: 100%;
}
.input-group-prepend{
	margin-right: -1px;
}
.input-group-text {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0 1rem;
    margin-bottom: 0;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.5;
    color: #0562e8;
    text-align: center;
    white-space: nowrap;
    background-color: #fff;
    border: 1px solid #ced4da;
	border-radius: 3px 0 0 3px;
	height: 42px;
}
.input-group > input {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    width: auto;
}
.form-group {
	display: block;
	width: 100%;
	margin: 0 0 20px;
	position: relative;
}
.form-group label {
	display: inline-block;
	margin: 0 2px 6px;
	font-weight: lighter;
	font-size: 1rem;
}
.form-group .with-error{
	display: none;
}
.form-group.has-error .with-error{
	display: block;
    color: #d50000;
    font-size: 13px!important;
	padding: 5px 0 0;
	margin: 0 !important;
}
.required {
	color: #c50e29;
	font-weight: lighter;
}
/*for password field with icon*/
.input-wrapper{
	position: relative;
 }
 .input-wrapper .eye-icon{
	position: absolute;
	top: 0;
	right: 10px;
	line-height: 48px;
	cursor: pointer;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	color: var(--theme-primary-color);
	font-size:var(--default-font-size);
 }