:root {
  --theme-primary-color: #0562e8;
  --theme-secondary-color: #0052ce;
--theme-border-color: #b6b6b6;
}
.form-control {
	width: 100%;
	height: 42px;
	border-width: var(--default-border-width);
	border-style:  solid;
	border-color:  var(--theme-border-color);
	border-radius: 3px;
	padding: 6px 16px;
	line-height: 40px;
	height: 42px;
	transition: all .4s ease;
	box-shadow: none;
	outline: none;
	font-size:var(--default-font-size);
}
.form-group label.radio-label{
  cursor: pointer;
  margin: 0rem 2rem 0 0;
}
.form-group label.radio-label:last-child{
	margin: 0 ;
  }
.form-group label.radio-label.radio-vertical{
	cursor: pointer;
    margin: 5px 2px 15px 5px ;
    display: flex ;
}
.form-group {
	display: block;
	width: 100%;
	margin: 0 0 20px;
	position: relative;
}
.form-group label {
	display: inline-block;
	margin: 0 2px 6px;
	font-weight: lighter;
	font-size: 1rem;
}
.input-wrapper.with-error{
	display: none;
}
.input-wrapper.has-error .with-error{
	display: block;
    color: #d50000;
    font-size: 13px!important;
	padding: 5px 0 0;
	margin: 0 !important;
}
.required {
	color: #c50e29;
	font-weight: lighter;
}
input[type='radio'].radio {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}
input[type='radio'].radio ~ .radiomark {
width: 22px;
height: 21px;
position: relative;
display: inline-block;
vertical-align: middle;
border: 1px solid #969696;
cursor: pointer;
margin-right: 10px;
border-radius: 50%;
}
input[type='radio'].radio:checked ~ .radiomark {
border-color: var(--theme-border-color);
}
input[type='radio'].radio:checked ~ .radiomark:after {
display: block;
}
.radiomark:after {
top: 3px;
left: 3px;
width: 13px;
height: 12px;
content: "";
position: absolute;
display: none;
border-radius: 50%;
background: var(--theme-primary-color);;
}
input[type='radio'].radio[disabled] ~.radiomark{
background-color: #ddd;
border-color:  var(--theme-border-color);
cursor: not-allowed;
}
input[type='radio'].radio:checked[disabled] ~.radiomark{
background-color: #ddd;
border-color:  var(--theme-border-color);
}
input[type='radio'].radio:checked[disabled] ~.radiomark:after{
background-color: #b6b6b6;
}