.checkbox-group {
	position: relative;
}
.checkbox-wrapper {
	vertical-align: middle;
	display: inline-block;
	width: 22px;
	height: 22px;
	position: absolute;
	left: 0;
	top: 0;
}
.checkbox-wrapper input.checkbox {
	width: 100%;
	height: 100%;
	opacity: 0;
	z-index: 10;
	cursor: pointer;
	position: relative;
	transition: 0.4s ease all;
}
.checkbox-wrapper .checkmark {
	position: absolute;
	width: 22px;
	display: block;
	background: #fff;
	border: 1px solid #ddd;
	border-radius: 2px;
	height: 22px;
	top: 0;
	left: 0;
	z-index: 9;
	transition: 0.4s ease all;
}
.checkbox-wrapper .checkmark::after {
	content: "";
	position: absolute;
	left: 6px;
	top: 2px;
	width: 7px;
	height: 13px;
	border: solid #ffffff;
	border-width: 0 2px 2px 0;
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
	visibility: hidden;
}
.checkbox-wrapper input:checked + .checkmark {
	background: #2096F3;
	border-color: #2096F3;
}
.checkbox-wrapper input:checked + .checkmark::after {
	color: #ffffff;
	visibility: visible;
}
.checkbox-group label.checkbox-label {
	margin: 0;
	font-weight: lighter;
	padding-left: 30px;
	vertical-align: middle;
	display: inline-block;
	font-size: inherit;
	cursor: pointer;
}
.checkbox-group.disabled .checkbox-label {
	cursor: not-allowed;
}
.checkbox-group.disabled .checkmark {
	cursor: not-allowed;
	background: #f0f0f0;
}
.checkbox-group.disabled .checkbox-wrapper input:checked + .checkmark {
	background: #ddd;
	border-color: #ddd;
}
