.dropdown {
	position: relative;
}
.dropdown-menu-outer{
	cursor: pointer;
	display: block;
	padding: 0 0.5rem;	
}
.dropdown-name{
	margin: 0 5px;
}
.icon-arrow-down {
    vertical-align: middle;
    display: inline-block;
    font-size: .8rem;
}
.dropdown .dropdown-menu {
	position: absolute;
	min-width: 150px;
	background: #ffffff;
	z-index: 99;
	box-shadow: 0 0 3px rgba(57, 57, 57, 0.3);
	border-radius: 1px 1px 4px 4px;
	right: 0;
	visibility: hidden;
	top: 50px;
	-ms-transform: translateY(-28px);
	transform: translateY(-28px);
	transition: transform 0.4s;
	opacity: 0;
}
.dropdown .dropdown-menu.open {
	-ms-transform: translateY(0);
	transform: translateY(0);
	visibility: visible;
	opacity: 1;
}
.dropdown .dropdown-menu li {
	margin: 0;
	padding: 0;
	display: block;
	line-height: 1.5;
}
 .dropdown .dropdown-menu li .dropdown-link {
	padding: 10px 12px;
	display: block;
	color: #4c4c4c;
	font-size: 15px;
	font-weight: lighter;
	cursor: pointer;
	transition: 0.4s ease all;
}
.dropdown .dropdown-menu li .dropdown-link:hover {
	color: #222;
	background-color: #eee;
}
.dropdown .dropdown-menu::before {
	position: absolute;
	content: "";
	right: 16px;
	border-left: 13px solid transparent;
	border-right: 13px solid transparent;
	border-bottom: 13px solid #e2dcdc;
	top: -14px;
}
.dropdown .dropdown-menu::after {
	position: absolute;
	content: "";
	right: 16px;
	border-left: 13px solid transparent;
	border-right: 13px solid transparent;
	border-bottom: 13px solid #ffffff;
	top: -12px;
}
