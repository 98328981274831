:root {
    --theme-primary-color: #0562e8;
    --theme-secondary-color: #0052ce;
    --theme-light-color: #0562e8;
    --default-font-size:1.2rem;
}
.btn {
	background: var(--theme-primary-color);
	padding: 0 16px;
	min-height: 42px;
	line-height: 40px;
	display: inline-block;
	border-radius: 3px;
	font-size:var(--default-font-size);
	min-width: 120px;
	text-align: center;
	transition: all .4s ease-out 0s;
	border: 1px solid var(--theme-primary-color);
	color: #fff;
	font-weight: inherit;
	outline: none;
	box-shadow: none;
}
.btn.btn-compact {
	min-height: 36px;
	line-height: 34px;
	font-size: 1rem;
	padding: 0 10px;
}
.btn.btn-large {
	min-height: 50px;
	line-height: 48px;
	font-size: 1.4rem;
}
.btn-outline{
    border: 1px solid var(--theme-primary-color);
    color: var(--theme-primary-color);
    background:none;
}
.btn-rounded{
    border-radius: 50px;
}
.btn .btn-icon, .btn .btn-text {
	vertical-align: middle;
	display: inline-block;
}
.btn.btn-icon.icon-left .btn-icon {
	margin-right: 10px;
}
.btn.btn-icon.icon-right .btn-icon {
	margin-left: 10px;
}

.btn.btn-icon-circle {
	width: 42px;
	height: 42px;
	min-width: auto;
	padding: 0 8px;
	border-radius: 100%;
}
.btn.btn-icon-regular {
	width: 42px;
	height: 42px;
	min-width: auto;
	padding: 0 8px;
}
html button[disabled]{
    opacity: .5;
    cursor: not-allowed;
}
.btn:hover{
	color:var(--theme-primary-color);
	background: #ffffff;
	border-color: var(--theme-primary-color);
}
.btn:focus{
	color: #fff;
	background: var(--theme-primary-color);
	border-color: var(--theme-primary-color);
}
.btn.btn-outline:focus {
	border: 1px solid var(--theme-primary-color);
    color: var(--theme-primary-color);
    background:none;
}
.btn.btn-outline:hover {
	color:#ffffff;
	background: var(--theme-primary-color);
}
.xd-btn-group {
	display: block;
	margin: 0 -0.5rem;
}
.xd-btn-group .btn {
	margin: 0 0.5rem;
}
.xd-btn-group.btn-joined .btn {
	border-radius: 0;
	margin: 0;
}
.xd-btn-group.btn-joined .btn:first-child {
	border-radius: 6px 0 0 6px;
	border-right-color: #f2f2f2;
}
.xd-btn-group.btn-joined .btn:last-child {
	border-radius: 0 6px 6px 0;
	border-left-color: #f2f2f2;
}