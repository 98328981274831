.xd-avatar-wrapper {
	display: inline-block;
	position: relative;
	overflow: hidden;
	vertical-align: middle;
}
.avatar-img {
	background: #e8e8e8;
	width: 100%;
	height: 100%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	font-size: 1.2rem;
	overflow: hidden;
}
.avatar-img img {
	width: 100%;
	max-width: 100%;
	text-align: center;
}
.avatar-text{
	width: 100%;
	height: 100%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	font-size: 1.2rem;
	overflow: hidden;
}
.xd-avatar-wrapper.xd-avatar-click {
	cursor: pointer;
}